<template>
    <div class="addSelectCheckup">
        <div class="contents">
            <p class="gray__title">본인의 희망에 따라 다음 검사를 추가 하실 수 있습니다.</p>
            <ul class="add_select_list">
                <l1 class="add_select_items">
                    <p>MRI + MRA (머리)</p>
                    <button data-alt="popup_content01">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>PET CT (전신)/PET CT (상반신)</p>
                    <button data-alt="popup_content02">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>심장CT(Ca-scoring)</p>
                    <button data-alt="popup_content03">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items"> 
                    <p>MD-CT 뇌 / 뇌혈관 / 경동맥 / 복부 · 골반 / 흉부</p>
                    <button data-alt="popup_content04">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>경동맥 초음파</p>
                    <button data-alt="popup_content05">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>갑상선 초음파</p>
                    <button data-alt="popup_content06">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>전립선 초음파</p>
                    <button data-alt="popup_content07">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>뇌혈류 초음파</p>
                    <button data-alt="popup_content08">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>심장 초음파</p>
                    <button data-alt="popup_content09">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>유방 초음파</p>
                    <button data-alt="popup_content010">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>수면 관리료 (수면내시경)</p>
                    <button data-alt="popup_content011">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>대장 내시경</p>
                    <button data-alt="popup_content012">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>골밀도 검사</p>
                    <button data-alt="popup_content013">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>운동부하 검사</p>
                    <button data-alt="popup_content014">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>스트레스 측정 검사</p>
                    <button data-alt="popup_content015">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>동맥경화도 측정 검사</p>
                    <button data-alt="popup_content016">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <l1 class="add_select_items">
                    <p>생체나이 측정 검사</p>
                    <button data-alt="popup_content017">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1>
                <!-- <l1 class="add_select_items">
                    <p>뇌심혈관계 발병 위험도 평가</p>
                    <button data-alt="popup_content018">
                        <span>자세히보기</span>
                        <img src="/resource/icon/add_select_btn.png" alt="이미지">
                    </button>
                </l1> -->
                <l1 class="add_select_items">
                    <p>HPV – DNA 검사</p>
                </l1>
                <l1 class="add_select_items">
                    <p>남성호르몬 검사</p>
                </l1>
                <l1 class="add_select_items">
                    <p>여성호르몬 검사</p>
                </l1>
            </ul>
        </div>
    </div>

    <div class="addSelectCheckup_popup">

        <div class="add_popup_wrap popup_content01" data-alt="popup_content01">
            <div class="add_popup_title">
                <h4>MRI + MRA 검사 머리</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>자기공명영상(MRI, Magnetic Resonance Imaging)이란 신체 부위에 자기장을 이용한 고주파를 발생시켜 신체내 수소 원자핵을 공명시킴으로서 발생되는 신호를 분석하여 영상화하는 기술이다.<br>자기공명혈관조영술(MRA)이란 두경부 혈관을 촬영하는 방법으로 뇌의 해부학적 구조는 볼 수 없으나, 혈관 영상을 통해 뇌동맥류의 유무와 뇌경색의 위험요인인 뇌동정맥기형, 동맥의 협착 등 뇌혈관의 이상 유무를 자세히 진단한다.<br>창원파티마병원에서 가동하고 있는 3.0T MRI는 기존 장비보다 자장의 세기가 강하여 높은 해상도의 영상으로 질환의 정확한 진단이 가능하다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_3.JPG" alt="MRI + MRA 검사 머리">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>MRI검사가 필요한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>뇌졸중(뇌출혈, 뇌경색), 뇌종양, 뇌외상 등 중추신경계 및 두경부 질환</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>추간판 탈출증(디스크), 퇴행성 척추 질환</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>선천성 심장질환, 판막질환</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>각종 관절의 인대, 연골, 반월판의 손상진단, 스포츠 관련 외상</p>
                    </div>
                </div>
            </div>
            <div class="contents add_popup_bottom">
                <h6>MRI 3.0T의 장점</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>넓은 출입구, 짧은 길이로 폐소공포증 및 소아환자도 안정된 상태에서 검사 가능</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>머리 및 관절 검사 시 최대 70% 소음 감소</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>짧은 검사시간</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>검사 중 움직임도 효과적으로 보정</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>고해상도의 영상 획득으로 정확한 판독 가능</p>
                    </div>
                </div>
            </div>

            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content02" data-alt="popup_content02">
            <div class="add_popup_title">
                <h4>PET - CT</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <h5>암 조기 진단 PET - CT</h5>
                <p>PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.<br>PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/pet_ct.JPG" alt="암 조기 진단 PET - CT">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>PET-CT의 유용성</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>양성종양과 악성종양의 감별</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>암 치료 효과 평가 및 재발진단</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>


        </div>
        <div class="add_popup_wrap popup_content03" data-alt="popup_content03">
            <div class="add_popup_title">
                <h4>심장CT(Ca-scoring)</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>심장에 피를 공급하는 혈관을 관상동맥이라 하며 이 혈관이 칼슘의 석회화로 인하여 막히게 되면 심장에 산소와 영양소를 공급하는 것이 어려워져서 협심증과 심근경색, 심부전, 부정맥 등의 심장질환을 일으키는 주요 원인이 된다. 이러한 관상동맥의 칼슘수치를 조영제를 사용하지 않고 CT촬영으로 검사한다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_4.JPG" alt="심장CT(Ca-scoring)">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>CT촬영으로 가능한 검사</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>협심증</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>심근경색</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>심부전</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>부정맥</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content04" data-alt="popup_content04">
            <div class="add_popup_title">
                <h4>128채널 MD-CT</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>CT(Computed Tomography)는 인체에 여러 개의 단면으로 방사선을 조사해 영상을 만드는 장비로 창원파티마병원에서 가동 중인 128채널 MD-CT는 고속촬영이 가능해 고화질의 영상을 빠른 시간 안에 얻을 수 있으며 저선량으로 방사선 피폭을 최소화 할 수 있어 보다 안전하고 정확한 진단이 가능하다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_4.png" alt="128채널 MD-CT">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>CT촬영으로 가능한 검사</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>뇌와 신체의 최신 검사 : 뇌출혈 검사, 복부장기검사, 뇌경색검사, 암의전이여부, 뇌관류검사, 전신의 혈관검사</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>흉부검사 : 폐암, 폐결절, 폐결핵, 폐기종, 흉통 등</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>복부검사 : 복부암(간암, 췌장암 등), 혈관이상유무, 체지방측정, 신장ㆍ요도ㆍ전립선ㆍ골반장기검사</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content05" data-alt="popup_content05">
            <div class="add_popup_title">
                <h4>경동맥 초음파</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>경동맥은 두개골 안으로 가는 혈액의 80%가 통과하는 목 부위의 중요한 혈관으로 경동맥 초음파 및 도플러 검사를 통해 경동맥 내 혈액의 흐름과 혈관의 상태를 평가할 수 있다. 또한 뇌로 가는 혈액의 흐름을 감소시키는 원인인 동맥경화를 예측할 수 있어 내막판 증식증(Intimal hyperplasia), 부분적 동맥 폐색, 동맥 협착 등이 있는지를 진단한다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_9.png" alt="경동맥 초음파">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>경동맥 초음파 검사가 필요한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>동맥경화성 심장질환자</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>65세 이상 고령자</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>고혈압, 당뇨, 고지혈증 등의 각종 성인병 환자</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>뇌졸중 및 심혈관 질환의 가족력이 있는 경우</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>일시적 뇌졸중 증상이 있는 경우(1차적으로 MRI검사 실시)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>일시적으로 팔 다리에 힘이 빠지는 경우</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>발음이 어눌해 지는 경우</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>중심 잡기가 어렵고 비틀거리는 경우</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>한쪽 얼굴이 저리거나 먹먹해지는 경우</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>표현능력이 떨어지거나 말을 잘 이해하지 못하는 경우</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content06" data-alt="popup_content06">
            <div class="add_popup_title">
                <h4>갑상선 초음파</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>갑상선 초음파는 갑상선 및 주변 기관의 형태적 이상에 대해서 평가하는 방법으로 갑상선결절에 대해서 다른 어떤 검사보다도 높은 해상도를 가지고 있는 검사이다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_9.png" alt="갑상선 초음파">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>갑상선 초음파로 진단 가능한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>갑상선 발달 이상</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>미만성 갑상선 질환(그레이브스병, 하시모토갑상선염 등)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>양선 결절질환(물혹 또는 증식성 결절)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>악성 결절 질환</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content07" data-alt="popup_content07">
            <div class="add_popup_title">
                <h4>전립선 초음파</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>전립선의 형태적 이상 유무 및 크기를 측정하며 초음파 진단기를 이용하면 장기의 단면을 여러 각도에서 손쉽게 볼 수 있으므로, 전립선의 질환도 보다 빠르고 정확하게 진단할 수 있다.<br>전립선 초음파는 항문을 통하여 직장 내 초음파 탐촉자를 삽입하여 직장의 전방에 위치하는 전립선의 이상을 진단하는 검사법이다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_9.png" alt="전립선 초음파">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>전립선 초음파로 진단이 가능한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>전립선 암</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>전립선 비대증</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>전립선 결석증</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content08" data-alt="popup_content08">
            <div class="add_popup_title">
                <h4>뇌혈류 초음파</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>뇌혈류 검사는 초음파를 이용하여 뇌 안의 혈관을 통한 혈액의 흐름을 평가하는 검사이다. 즉 혈관의 상태를 직접 관찰하는 것이 아니라 혈액이 흐르는 속도, 방향 등의 자료를 바탕으로 좁아졌는지, 막혔는지 등을 판단하게 된다. 창원파티마병원에서 가동 중인 디지털 M-MODE 뇌혈류 진단기는 두개 내 약 5cm 사이의 혈류의 강도와 방향을 한눈에 볼 수 있어 기존의 기기보다 음향창을 더 찾기가 용이하며 혈관이 구부러진 경우에도 편리하게 추적할 수 있어 뇌졸중, 어지럼증, 두통의 진단에 큰 도움이 되고 있다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_12.png" alt="뇌혈류 초음파">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>뇌혈류 검사가 필요한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>뇌졸중의 조기 진단</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>만성두통, 편두통</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>어지러움증</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>기타 동맥경화증, 뇌혈관질환 후유증(마비, 발음장애 등) 진단</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content09" data-alt="popup_content09">
            <div class="add_popup_title">
                <h4>심장 초음파</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>심장초음파(ECHOCARDIOGRAPHY) 검사는 초음파를 이용해서 심장의 크기 및 기능을 측정할 수 있어 심장의 각종질환을 손쉽게 진단할 수 있다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_9_02.png" alt="심장 초음파">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>심장초음파 검사가 필요한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>호흡곤란</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>흉통</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>빈맥(맥박수가 분당 100회 이상일 경우. 정상인의 경우 맥박은 분당 60~100회)</p>
                    </div>
                </div>
            </div>

            <div class="contents add_popup_bottom">
                <h6>심장초음파 검사로 진단이 가능한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>심장 판막 질환, 판막수술 이후의 후유증 진단</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>심내막염의 진단</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>선천성 심질환</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>심근질환(비후성, 확장선, 제한성 심근병증의 구별)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>심낭질환(심낭삼출, 교액성 심낭염의 진단)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>허혈성 심질환(심근경색, 심부전)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>종괴 및 대동맥질환</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content010" data-alt="popup_content010">
            <div class="add_popup_title">
                <h4>유방 초음파</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>유방 초음파는 선형 탐촉자를 이용한 고해상도 초음파 기기를 이용하여 유방 질환을 진단하는 검사이다. 유방에 멍울이 만져지거나, 혈성 유두 분비의 증상이 있는 여성에서 1차 검사로 추천할 수 있는 검사법이다. 방사선 노출이 없으며 유방의 양성 혹(섬유선종), 악성 혹(유방암), 염증성 병변(농양), 물혹(낭종) 등을 영상 소견으로 구분하는 데에 정확도가 높다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_9.png" alt="유방 초음파파">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>유방초음파 검사로 진단이 가능한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>유방암</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>유방의 양성혹(섬유선종)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>물혹(낭종)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>농양</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content011" data-alt="popup_content011">
            <div class="add_popup_title">
                <h4>수면 내시경</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>일반내시경과 수면내시경은 모두 내시경 관을 입을 통해 삽입하여 식도, 위 등을 직접 관찰 한다.<br>일반내시경인 경우 의식이 있는 상태로 검사하기 때문에 검사하는 동안 고통을 느낄 수 있어 편안한 검사를 원할 경우 수면 위내시경 검사를 실시한다.<br>수면 내시경은 의식하 진정내시경이라고도 하는데 검사 동안 가벼운 진정제를 사용하여 순간적으로 가수면 상태로 유도한다.<br>마취제를 사용하는 것이 아니라 진정제를 정맥 주사하여 가볍게 잠드는 것이므로 사람에 따라 약간의 고통을 느낄 수도 있고, 수면 유도가 잘 되지 않을 경우도 있다.</p>
            </div>
            <!-- <div class="contents img_bg">
                <img src="/resource/img/equipment_9.png" alt="유방 초음파">
            </div> -->
            <div class="contents add_popup_bottom">
                <!-- <h6>유방초음파 검사로 진단이 가능한 경우</h6> -->
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>뇌졸중, 심장질환, 폐질환 등의 질환이 있거나, 수술 및 입원치료를 받으신 분은 복용하시는 약물에 대하여 주치의에게 확인 한 후 검진 여부를 결정하셔야 합니다.</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>혈압약, 심장약, 항경련제를 복용하시는 분은 검진 당일 오전 6시경에 소량의 물로 복용하시면 됩니다. (단, 항응고제는 복용이 불가합니다.)</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>검진 전날 저녁 또는 당일 아침 인슐린 주사나 당뇨약 복용은 불가합니다.</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>아스피린 등 혈전용해제 성분의 약을 복용하시는 분은 조직검사 시 출혈의 위험이 있으므로 주치의 상담 후 검사 1주일 전부터 복용을 중단하셔야 합니다.</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>복용하시는 약에 대한 조정이 안 되신 분은 조직검사를 실시하지 못할 수도 있습니다. (복용중단이 어려운 분은 사전에 검진센터로 연락 바랍니다.)</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content012" data-alt="popup_content012">
            <div class="add_popup_title">
                <h4>대장 내시경</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>항문으로 내시경이라는 특수한 카메라를 삽입하여, 대장 내부 및 대장과 인접한 소장의 말단 부위까지를 관찰하는 검사법이다. 대장내시경은 특히 대장암과 염증성 장질환의 진단에 매우 중요한 검사이다. 갑작스러운 혈색소(헤모글로빈 hemoglobin)의 감소 등 빈혈의 징후가 있는 경우에는 대변에서 혈색소가 검출되지 않더라도 상부 위장관(위, 식도, 십이지장)내시경과 함께 흔히 대장내시경을 시행한다.</p>
            </div>
            <div class="contents img_bg">
                <img src="/resource/img/equipment_15.png" alt="대장 내시경">
            </div>
            <div class="contents mt60 add_popup_bottom">
                <h6>대장내시경 검사로 진단이 가능한 경우</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>대장암</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>대장 용종</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>염증성 장질환</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>감염성 대장염</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>장의 림프선 종양</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>대장 결핵</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>대장 출혈</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>게실 질환</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>대장의 혈관 이형성증</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>허혈성 장염</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>궤양</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>장 착색</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>치질</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>대장 협착</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content013" data-alt="popup_content013">
            <div class="add_popup_title">
                <h4>골밀도 검사</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>뼈의 밀도(단단한 정도)를 컴퓨터를 통해 촬영하여 골무기질의 함량을 측정하는 검사이며, 요추의 골밀도를 측정하여 동연령 사람들의 평균치와 비교하여 진단한다.</p>
            </div>
            <!-- <div class="contents img_bg">
                <img src="/resource/img/equipment_15.png" alt="대장 내시경">
            </div> -->
            <div class="contents mt60 add_popup_bottom">
                <h6>관련질환</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>골다공증, 골감소증 등</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content014" data-alt="popup_content014">
            <div class="add_popup_title">
                <h4>운동부하 검사</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>관상동맥질환이 있는 경우 허혈을 시사하는 심전도 변화나 흉통의 발생을 기록하는 협심증의 진단에 매우 유용한 검사이다.</p>
            </div>
            <!-- <div class="contents img_bg">
                <img src="/resource/img/equipment_15.png" alt="대장 내시경">
            </div> -->
            <div class="contents mt60 add_popup_bottom">
                <h6>관련질환</h6>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>관상동맥질환, 부정맥 등</p>
                    </div>
                </div>
            </div>
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content015" data-alt="popup_content015">
            <div class="add_popup_title">
                <h4>스트레스 측정 검사</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>우리의 신체 조절 능력과 스트레스 저항도, 피로도 등 전반적인 건강 상태를 확인 할 수 있는 검사이다.</p>
            </div>
            <!-- <div class="contents img_bg">
                <img src="/resource/img/equipment_15.png" alt="대장 내시경">
            </div> -->
            <!-- <div class="contents mt60 add_popup_bottom">
              <h6>관련질환</h6>
              <div class="depth__wrap ">
                  <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>관상동맥질환, 부정맥 등</p>
                    </div>
                </div>
            </div> -->
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content016" data-alt="popup_content016">
            <div class="add_popup_title">
                <h4>동맥경화도 측정 검사</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>사지의 혈압, 심전도, 심음도, 맥파 전파속도를 측정하여 동맥경화도를 측정하는 검사이다.</p>
            </div>
            <!-- <div class="contents img_bg">
                <img src="/resource/img/equipment_15.png" alt="대장 내시경">
            </div> -->
            <!-- <div class="contents mt60 add_popup_bottom">
            <h6>관련질환</h6>
            <div class="depth__wrap ">
                <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>관상동맥질환, 부정맥 등</p>
                    </div>
                </div>
            </div> -->
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content017" data-alt="popup_content017">
            <div class="add_popup_title">
                <h4>생체나이 측정 검사</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>나이에 따른 전반적인 건강과 노화 상태를 의미하며 모든 임상검사 결과를 기반으로 측정한 검사이다.</p>
            </div>
            <!-- <div class="contents img_bg">
                <img src="/resource/img/equipment_15.png" alt="대장 내시경">
            </div> -->
            <!-- <div class="contents mt60 add_popup_bottom">
            <h6>관련질환</h6>
            <div class="depth__wrap ">
                <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>관상동맥질환, 부정맥 등</p>
                    </div>
                </div>
            </div> -->
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
        <div class="add_popup_wrap popup_content018" data-alt="popup_content018">
            <div class="add_popup_title">
                <h4>뇌심혈관계 발병 위험도 평가</h4>
                <a href="javascript:;"><span></span><span></span></a>
            </div>
            <div class="contents mt60">
                <p>미국의 Framingham Risk Score에 따른 이 평가는 성인의 성별, 연령, 저밀도 콜레스테롤(LDL), 고밀도 콜레스테롤(HDL), 수축기 혈압, 흡연, 당뇨 등의 위험인자로 관상동맥심장질환이 발병할 확률을 평가한 검사이다.</p>
            </div>
            <!-- <div class="contents img_bg">
                <img src="/resource/img/equipment_15.png" alt="대장 내시경">
            </div> -->
            <!-- <div class="contents mt60 add_popup_bottom">
            <h6>관련질환</h6>
            <div class="depth__wrap ">
                <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>관상동맥질환, 부정맥 등</p>
                    </div>
                </div>
            </div> -->
            <div class="contents mt60 btn-wrap center"><a href="javascript:;" class="btn">닫기</a></div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            course: this.$route.query.course ? this.$route.query.course : '',

        }
    },
    methods: {

    },
    mounted() {
        // 팝업 닫기 기능
        document.querySelectorAll('.add_popup_title a, .btn-wrap .btn').forEach(function (el) {
            el.addEventListener('click', function () {
                document.querySelector('.addSelectCheckup_popup').style.display = 'none';
            });
        });

        // 팝업 열기 기능
        const buttons = document.querySelectorAll('.add_select_items button');
        const popups = document.querySelectorAll('.add_popup_wrap');

        buttons.forEach(button => {
            button.addEventListener('click', function () {
                const targetPopup = button.getAttribute('data-alt');
                popups.forEach(popup => {
                    if (popup.getAttribute('data-alt') === targetPopup) {
                        popup.style.display = 'block';
                    } else {
                        popup.style.display = 'none';
                    }
                });
                document.querySelector('.addSelectCheckup_popup').style.display = 'flex';
            });
        });
    },
}
</script>