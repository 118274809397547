<template>
    <div class="programInfo">
        <div class="tab-menu__wrap">
            <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item" :class="{active : course === 'male' || course === '' }">
                    <button type="button" class="tab-menu__btn" @click="course = 'male'">남성정밀코스</button>
                </li>
                <li class="tab-menu__item" :class="{active : course === 'femaleA' }">
                    <button type="button" class="tab-menu__btn" @click="course = 'femaleA'">여성정밀A코스</button>
                </li>
                <li class="tab-menu__item" :class="{active : course === 'femaleB' }">
                    <button type="button" class="tab-menu__btn" @click="course = 'femaleB'">여성정밀B코스</button>
                </li>
                <li class="tab-menu__item" :class="{active : course === 'dige' }">
                    <button type="button" class="tab-menu__btn" @click="course = 'dige'">소화기정밀코스</button>
                </li>
                <li class="tab-menu__item" :class="{active : course === 'resp' }">
                    <button type="button" class="tab-menu__btn" @click="course = 'resp'">호흡기정밀코스</button>
                </li>
                <li class="tab-menu__item" :class="{active : course === 'circ' }">
                    <button type="button" class="tab-menu__btn" @click="course = 'circ'">순환기정밀코스</button>
                </li>
                <li class="tab-menu__item" :class="{active : course === 'brain' }">
                    <button type="button" class="tab-menu__btn" @click="course = 'brain'">뇌 정밀코스</button>
                </li>
            </ul>
            </div>
        </div>
        

        <div class="contents" :class="{active : course === 'male' || course === '' }">

            <div ref="programInfo" class="program_add_wrap">

                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">남성정밀코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            남성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한 맞춤형 건강검진 프로그램입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~금요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 930,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive }" @click="addClass">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="14" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능,
                                    지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B,
                                    C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사,
                                    정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암,
                                    전립선암)
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                                <td>대장내시경 또는 저선량 흉부 CT 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                                <td>전립선초음파, 감상선초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                                <td>남성호르몬</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" :class="{active : course === 'femaleA' }">
            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">여성정밀A코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            여성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한 맞춤형 건강검진 프로그램입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~금요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 1,100,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive2 }" @click="addClass2">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="17" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 난소암)
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                                <td>대장내시경</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                                <td>갑상선초음파, 유방초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                                <td>골밀도검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                                <td>여성호르몬</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 액상자궁경부암검사, 인유두종바이러스(HPV-DNA)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" :class="{active : course === 'femaleB' }">
            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">여성정밀B코스 (대장내시경 제외)</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            여성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한 맞춤형 건강검진 프로그램입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~금요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 950,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive3 }" @click="addClass3">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="16" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 난소암)
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                                <td>갑상선초음파, 유방초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                                <td>골밀도검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                                <td>여성호르몬</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 액상자궁경부암검사, 인유두종바이러스(HPV-DNA)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" :class="{active : course === 'dige' }">

            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">소화기정밀코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            각종 성인병의 조기발견 및 소화기 계통의 질환, 암 등의 조기진단과 예방을 위한 맞춤형 건강검진 프로그램입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~금요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 남 900,000원 / 여 950,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive4 }" @click="addClass4">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="13" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀)), 헬리코박터 면역검사
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부초음파, 복부CT</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                                <td>대장내시경</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                                <td>골밀도검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사</td>
                            </tr>
                            <tr>
                                <td rowspan="2" style="text-align: center;">여성</td>
                                <td>유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <div class="contents" :class="{active : course === 'resp' }">

            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">호흡기정밀코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            각종 성인병의 조기발견 및 폐질환, 폐암 등의 조기진단과 예방을 위한 맞춤형 건강검진 프로그램입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~토요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 남 700,000원 / 여 750,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive5 }" @click="addClass5">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="11" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암2종(NSE, CYFRA21-1), 전립선암(♂),
                                    난소암(♀))
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 객담세포진 검사, 저선량 흉부CT</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사</td>
                            </tr>
                            <tr>
                                <td rowspan="2" style="text-align: center;">여성</td>
                                <td>유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <div class="contents" :class="{active : course === 'circ' }">

            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">순환기정밀코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            각종 성인병의 조기발견 및 심혈관 질환의 조기진단과 예방, 흡연, 고혈압, 당뇨, 심장질환의 가족력 등 심혈관 질환의 위험인자가 있는
                                            분들을 위한 맞춤형 건강검진 프로그램입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~금요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 남 950,000원 / 여 1,000,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive6 }" @click="addClass6">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="12" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀)), 심혈관정밀3종(호모시스테인,
                                    CPK, CRF)
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도, 심장초음파, 심장CT(Ca-scoring)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                                <td>경동맥 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사, 동맥경화도검사</td>
                            </tr>
                            <tr>
                                <td rowspan="2" style="text-align: center;">여성</td>
                                <td>유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" :class="{active : course === 'brain' }">

            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">뇌 정밀코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            각종 성인병의 조기발견, 뇌혈관 질환(뇌졸중 등)의 조기진단과 예방 및 가족력, 위험인자가 있는 분들을 위한 맞춤형 건강검진
                                            프로그램입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~금요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 남 1,400,000원 / 여 1,450,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive7 }" @click="addClass7">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="13" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀))
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">뇌검사</td>
                                <td>뇌 MRI &amp; MRA</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">경동맥검사</td>
                                <td>경동맥 CTA 또는 경동맥초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사, 동맥경화도검사, 스트레스측정검사</td>
                            </tr>
                            <tr>
                                <td rowspan="2" style="text-align: center;">여성</td>
                                <td>유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-wrap center contents"><a href="javascript:;" class="btn" @click="getCheckup">예약하기</a></div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            course: this.$route.query.course ? this.$route.query.course : '',
            isActive: false,
            isActive2: false,
            isActive3: false,
            isActive4: false,
            isActive5: false,
            isActive6: false,
            isActive7: false,

        }
    },
    methods: {
        getCheckup() {
            let program = ''
            if(this.course === 'male' || this.course === ''){
                program = '남성정밀코스'
            } else if(this.course === 'femaleA'){
                program = '여성정밀 A코스'
            } else if(this.course === 'femaleB'){
                program = '여성정밀 B코스'
            } else if(this.course === 'dige'){
                program = '소화기정밀코스'
            } else if(this.course === 'resp'){
                program = '호흡기정밀코스'
            } else if(this.course === 'circ'){
                program = '순환기정밀코스'
            } else if(this.course === 'brain'){
                program = '뇌정밀코스'
            }

            if (this.$store.state.memberStore.status && this.$store.state.myInfoStore.memGender) {
                if (program == '여성정밀 A코스' && this.$store.state.myInfoStore.memGender == 'M') {
                    alert('해당 코스는 여성전용 건강검진 코스입니다.')
                    return
                }
            }
            const data = {
                program: program,
                option: []
            }

            console.log(data)
            this.$store.commit('setCprsvInfo', data)
            const url = `/deptHp/${this.$route.params.deptCd}/${this.$route.params.hpUrl}/PersonalReservation.do`
            window.open(url)
            // this.$router.push(url)
        },
        addClass() {
            if (!this.isActive) {
                this.isActive = true;
            }
        },
        addClass2() {
            if (!this.isActive2) {
                this.isActive2 = true;
            }
        },
        addClass3() {
            if (!this.isActive3) {
                this.isActive3 = true;
            }
        },
        addClass4() {
            if (!this.isActive4) {
                this.isActive4 = true;
            }
        },
        addClass5() {
            if (!this.isActive5) {
                this.isActive5 = true;
            }
        },
        addClass6() {
            if (!this.isActive6) {
                this.isActive6 = true;
            }
        },
        addClass7() {
            if (!this.isActive7) {
                this.isActive7 = true;
            }
        },
    },
   mounted() {
         const buttons = document.querySelectorAll('.tab-menu__item');
            buttons.forEach(button => {
            button.addEventListener('click', (event) => {
                buttons.forEach(btn => btn.classList.remove('on'));
                event.currentTarget.classList.add('on');
            });
        });
        
    },
}
</script>