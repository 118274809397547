<template>
    <header class="header">
        <!-- banner -->
        <div class="top-banner" v-if='topBanner && mainPage && lineBanner.length > 0'>
            <swiper :spaceBetween="10" :loop="true" :autoplay="autoplayOptions" :speed="1200" :modules="modules" class="mySwiper">
                <swiper-slide v-for="i in lineBanner.slice().sort((a, b) => a.bannerNo - b.bannerNo)" :key="i">
                    <img :src="`/displayFile.do?name=${i.attachBag.bannerImg[0].savedName}`" :alt="i.attachBag.bannerImg[0].altValue" @click="goBannerLink(i)" style="cursor:pointer;">
                </swiper-slide>
            </swiper>
            <div class="agree-input">
                <label for="agree-n" class="agree-input__item" @click="closeBanner">
                    <input type="checkbox" id="banner-n" class="agree-input__radio" name="banner" checked="">
                    <span class="icon"></span>
                    오늘 하루 열지 않기
                </label>
                <div class="close" @click="bannerFn">
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <!-- 멀티팝업 -->
        <div class="popup-zone" v-if='popupZone && mainPage && popupBannerList.length > 0'>
            <div class="popup-zone__inner">
                <h2><img src="@/assets/resource/icon/logo.png" alt="이미지"> popup-zone</h2>
                <div class="num">팝업 전체 &#183; {{ popupBannerList.length }}개</div>

                <swiper :spaceBetween="40" :loop="true" :breakpoints="{
                        300: { slidesPerView: 1 },
                        721: { slidesPerView: 2 },
                        1400: { slidesPerView: 3 }
                    }"
                    :navigation="{
                        nextEl: '.popup-zone .next',
                        prevEl: '.popup-zone .prev'
                    }" :modules="modules">
                    <swiper-slide v-for="(banner, idx) in popupBannerList" :key="idx">
                        <component :is="isExternal(banner.link) ? 'a' : 'router-link'" :href="banner.link" :to="isExternal(banner.link) ? null : banner.link" :target="banner.target">
                            <img :src="`/displayFile.do?attachNo=${banner.attachBag.bannerImg[0].attachNo}`" alt="이미지">
                        </component>
                    </swiper-slide>
                </swiper>
                <div class="swiper-btn">
                    <button type="button" class="prev"><img src="@/assets/resource/icon/arrow-white.png" alt="이미지"></button>
                    <button type="button" class="next"><img src="@/assets/resource/icon/arrow-white.png" alt="이미지"></button>
                </div>
                <div class="popup-zone__btn">
                    <button type="button" class="pop-up__btn-b" @click="closePopup">오늘 하루 열지 않기 <img src="@/assets/resource/icon/close.png" alt="이미지"></button>
                    <button type="button" class="pop-up__btn" @click="popupFn">
                        <div class="pop-up__btn-inner"><span></span></div>
                    </button>
                </div>
            </div>
        </div>
        <!-- <div class="popup-zone">
            <div class="popup-zone__inner">
                <h2><img src="@/assets/resource/icon/logo.png" alt="이미지"> popup-zone</h2>
                <div class="num">팝업 전체 &#183; 4개</div>
                <div class="swiper-wrap">
                    <div class="swiper-btn">
                        <button type="button" class="prev"><img src="@/assets/resource/icon/arrow-white.png" alt="이미지"></button>
                        <button type="button" class="next"><img src="@/assets/resource/icon/arrow-white.png" alt="이미지"></button>
                    </div>
                    <swiper :spaceBetween="30" :loop="true" :slidesPerView="3" :breakpoints="{
                        300: { slidesPerView: 1 },
                        721: { slidesPerView: 2 },
                        1400: { slidesPerView: 3 }
                    }">
                        <swiper-slide><img src="@/assets/resource/img/popupsample.png" alt="이미지"></swiper-slide>
                        <swiper-slide><img src="@/assets/resource/img/popupsample2.png" alt="이미지"></swiper-slide>
                        <swiper-slide><img src="@/assets/resource/img/popupsample3.png" alt="이미지"></swiper-slide>
                    </swiper>
                </div>
                <div class="popup-zone__btn">
                    <button type="button" class="pop-up__btn-b">오늘 하루 열지 않기 <img src="@/assets/resource/icon/close.png" alt="이미지"></button>
                    <button type="button" class="pop-up__btn">
                        <div class="pop-up__btn-inner"><span></span></div>
                    </button>
                </div>
            </div>
        </div> -->

        <div class="header__inner">
            <div class="header__front">
                <h1 class="header__tit">
                    <a href="javascript:;" @click="goHome">
                        <img src="@/assets/resource/icon/logo-b.png" alt="이미지">
                    </a>
                </h1>
                <ul class="header__list" @mouseleave="resetHeaderActive">
                    <template v-for="(item, index) in menuList" :key="index">
                        <template v-if="item.depth == 1 && item.gnbYn=='Y'">
                            <li class="header__item">
                                <a href="javascript:;" class="header__link" @mouseover="showContent(item)">{{item.menuName}}</a>
                                <slide-up-down class="header__cont-box" :class="{'on': topBanner && mainPage}" :active="activeMenu.menuName !== undefined">
                                    <div class="header__cont-inner-wrap">
                                        <div class="header__cont-inner">
                                            <div class="header__cont-info" v-if="activeMenu.menuName === '이용안내'">
                                                <div class="header__cont-info-inner">
                                                    <p class="tit">이용안내</p>
                                                    <p class="desc">창원파티마병원에서 제공드리는 <br>
                                                        여러 서비스를 안내해드립니다</p>
                                                    <div class="info-box">
                                                        <div class="info-box__text">
                                                            <span class="info-box__tit">통합컨택센터(예약·상담)</span>
                                                            <span class="info-box__num"><a href="tel:0552701000" class="c-blue">270-1000</a></span>
                                                        </div>
                                                    </div>
                                                    <div class="info-box">
                                                        <div class="info-box__text">
                                                            <span class="info-box__tit">진료시간</span>
                                                        </div>
                                                        <div class="info-box__time">
                                                            <span class="info-box__tit">평일</span>
                                                            <span class="info-box__num">09:00 ~ 17:00</span>
                                                        </div>
                                                        <div class="info-box__time">
                                                            <span class="info-box__tit">토요일</span>
                                                            <span class="info-box__num">09:00 ~ 12:00 </span>
                                                        </div>
                                                        <p class="info-box__desc">응급, 분만환자는 24시간 응급의료센터를
                                                            이용할 수 있습니다</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="header__cont-info" v-if="activeMenu.menuName === '진료과/의료진'">
                                                <div class="header__cont-info-inner">
                                                    <p class="tit">진료과/의료진</p>
                                                    <p class="desc">창원파티마병원이 자랑하는 의료진과 <br>
                                                        다양한 진료과 및 센터 등을 안내해드립니다</p>
                                                    <div class="header__search">
                                                        <div class="header__search-tit">의료진 찾기</div>
                                                        <div class="header__search-input">
                                                            <label>
                                                                <input type="text" placeholder="의료진 입력" v-model="drName">
                                                                <button type="button" class="btn blue" @click="searchDr">검색</button>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="header__cont-info" v-if="activeMenu.menuName === '예약/발급'">
                                                <div class="header__cont-info-inner">
                                                    <p class="tit">예약/발급</p>
                                                    <p class="desc">창원파티마병원이 자랑하는 의료진과 <br>
                                                        다양한 진료과 및 센터 등을 안내해드립니다</p>
                                                    <div class="header__search">
                                                        <div class="header__search-tit"><img src="@/assets/resource/icon/header-icon5.png" alt="이미지"> 첫 방문 간편예약</div>
                                                        <div class="header__search-input">
                                                            <label class="type2">
                                                                <input type="text" placeholder="이름" v-model="patName">
                                                                <input type="text" placeholder="전화번호" maxlength="11" @input="this.$commonUtils.numberOnly" v-model="patHpNo">
                                                                <button type="button" class="btn blue" @click="[this.$store.commit('setFirst', {name: this.patName, hpNo: this.patHpNo}), this.patName = '', this.patHpNo = '', this.$commonUtils.showPopup()]">신청</button>
                                                            </label>
                                                            <div class="btn-wrap center"><a href="/reserve/reservation.do" class="btn blue" target="_blank">온라인 예약</a><a href="/hospital/reservation/HealthReserve.do" class="btn mint">건강검진 예약</a></div>
                                                            <div class="text-box">
                                                                <p>전화예약 <span><a class="c-blue" href="tel:055-270-1000"> 055) 270-1000</a></span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="header__cont-info" v-if="activeMenu.menuName === '건강정보'">
                                                <div class="header__cont-info-inner">
                                                    <p class="tit">건강정보</p>
                                                    <div class="header__search">
                                                        <div class="header__search-tit"><img src="@/assets/resource/icon/search-gray2.png" alt="search-icon"> 질환명 검색</div>
                                                        <div class="header__search-input">
                                                            <label>
                                                                <input type="text" placeholder="두통" v-model="title">
                                                                <button type="button" class="btn blue" @click="goDiseaseList">검색</button>
                                                            </label>
                                                        </div>
                                                        <ul class="icon-box">
                                                            <li class="item">
                                                                <a href="https://www.fatimahosp.co.kr/mypage/result/out.do" target="_blank">
                                                                    <div class="icon-tit">나의 진료이력</div>
                                                                    <img src="@/assets/resource/icon/header-icon6.png" alt="이미지">
                                                                </a>
                                                            </li>
                                                            <li class="item">
                                                                <a href="https://www.fatimahosp.co.kr/mypage/health/healthCare.do" target="_blank">
                                                                    <div class="icon-tit">나의 건강관리</div>
                                                                    <img src="@/assets/resource/icon/header-icon7_01.png" alt="이미지">
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="header__cont-info" v-if="activeMenu.menuName === '병원소개'">
                                                <div class="header__cont-info-inner">
                                                    <p class="tit">병원소개</p>
                                                    <ul class="header__board-list">
                                                        <li class="header__board-item item1">
                                                            <a href="/healthplus/list.do">파티마 <br> 건강플러스</a>
                                                        </li>
                                                        <li class="header__board-item item2">
                                                            <a href="/fatimasam/list.do">파티마샘</a>
                                                        </li>
                                                        <li class="header__board-item item3">
                                                            <a href="/news/list.do">파티마뉴스</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
    
    
                                            <ul class="header__cont-list" v-if="activeMenu.menuName !== '진료과/의료진'">
                                                <template v-for="(el, index) in menuList" :key="index">
                                                    <template v-if="el.depth == 2 && el.gnbYn=='Y' && el.parentMenuNo == activeMenu.menuNo">
                                                        <li class=" header__cont-item">
                                                            <h2 class="header__cont-tit">
                                                                <component :is="el.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="el.menuUrl" class="header__deps1-link" :to="el.menuUrl" :target="el.linkMethod" @click="resetHeaderActive">{{el.menuName}}</component>
                                                            </h2>
                                                            <ul class="header__deps2-list">
                                                                <template v-for="(data, index) in menuList" :key="index">
                                                                    <template v-if="data.depth == 3 && data.gnbYn=='Y' && el.menuNo == data.parentMenuNo">
                                                                        <li class="header__deps2-item">
                                                                            <component :is="data.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="data.menuUrl" class="header__deps2-link" :to="data.menuUrl" :target="data.linkMethod" @click="resetHeaderActive">
                                                                                {{data.menuName}}</component>
                                                                        </li>
                                                                    </template>
                                                                </template>
                                                            </ul>
                                                        </li>
                                                    </template>
                                                </template>
                                            </ul>
                                            <!-- 진료과/의료진 일때만 -->
                                            <ul class="header__cont-list type2" v-if="activeMenu.menuName === '진료과/의료진'">
                                                <template v-for="(el, index) in menuList" :key="index">
                                                    <template v-if="el.depth == 2 && el.gnbYn=='Y' && el.parentMenuNo == activeMenu.menuNo">
                                                        <li class=" header__cont-item">
                                                            <h2 class="header__cont-tit">
                                                                <component :is="el.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="el.menuUrl" class="header__deps1-link" :to="el.menuUrl" :target="el.linkMethod" @click="resetHeaderActive">
                                                                    <span class="icon">
                                                                        <img :src="require('@/assets/resource/icon/header-icon' + (el.menuNo) + '.png')" alt="icon">
                                                                    </span>
                                                                    <span class="name">{{el.menuName}}</span>
                                                                </component>
                                                            </h2>
                                                        </li>
                                                    </template>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </slide-up-down>

                            </li>
                        </template>
                    </template>
                </ul>
                <div class="header__menu">
                    <router-link to="/member/login.do" class="header__menu-link login" v-if="!$store.state.memberStore.status" @click="navRemove">로그인</router-link>
                    <router-link to="/member/join.do" class="header__menu-link join" v-if="!$store.state.memberStore.status" @click="navRemove">회원가입</router-link>
                    <a href="javascript:;" class="header__menu-link login" v-if="$store.state.memberStore.status" @click="logout">로그아웃</a>
                    <router-link to="/mypage/mypage.do" class="header__menu-link join" v-if="$store.state.memberStore.status" @click="navRemove">마이페이지</router-link>
                    <a v-if="$route.path !== '/search.do'" href="javascript:;" class="header__menu-link search" @click="searchBtn"><img src="@/assets/resource/icon/search-black.png" alt="검색하기"></a>
                    <a href="javascript:;" class="header__menu-link" title="전체메뉴 보기" @click="navClick()">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </a>
                </div>
            </div>
        </div>

        <!-- 사이트맵 -->
        <div class="site-map">
            <ul class="site-map__list">
                <template v-for="(item, index) in menuList" :key="index">
                    <template v-if="item.depth == 1 && item.gnbYn == 'Y'">
                        <li class="site-map__item">
                            <h2>{{item.menuName}}</h2>
                            <ul class="deps2__list">
                                <template v-for="(el, index) in menuList" :key="index">
                                    <template v-if="el.depth == 2 && el.gnbYn=='Y' && el.parentMenuNo == item.menuNo">
                                        <li class="deps2__item">
                                            <component :is="el.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="el.menuUrl" class="deps2__link" :to="el.menuUrl" :target="el.linkMethod" @click="[navClick(), resetHeaderActive()]">{{el.menuName}}</component>
                                            <ul class="deps3__list">
                                                <template v-for="(data, index) in menuList" :key="index">
                                                    <template v-if="data.depth == 3 && data.gnbYn=='Y' && el.menuNo == data.parentMenuNo">
                                                        <li class="deps3__item">
                                                            <component :is="data.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="data.menuUrl" :to="data.menuUrl" :target="data.linkMethod" @click="[navClick(), resetHeaderActive()]">
                                                                <span class="name">{{data.menuName}}</span>
                                                            </component>
                                                        </li>
                                                    </template>
                                                </template>
                                            </ul>
                                        </li>
                                    </template>
                                </template>
                            </ul>
                        </li>
                    </template>
                </template>
            </ul>
        </div>

        <!-- Search -->
        <a href="javascript:;" class="sub-search">
            <div class="inner">
                <div class="close" @click="searchBtn">
                    <div class="close__inner">
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>
                </div>
                <div class="contents">
                    <Search :searchArr="searchArr" @setState="search"></Search>
                    <ul class="re-search__list">
                        <li class="re-search__item" v-for="(hashtag, index) in hashTags" :key="index" >
                            <a href="javascript:;" @click="search('', hashtag.codeName)">#{{hashtag.codeName}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </a>
    </header>
</template>

<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Autoplay, Navigation } from 'swiper/modules';
    import SlideUpDown from 'vue-slide-up-down'
    import { ScrollTrigger } from "gsap/ScrollTrigger";
    import Search from '../common/SearchBox.vue'
    import 'swiper/css';
    // gsap.registerPlugin(ScrollTrigger);

    export default {
        components: {
            SlideUpDown,
            Search,
            Swiper,
            SwiperSlide,

        },
       setup() {
            const autoplayOptions = {
                delay: 2500,
                disableOnInteraction: false
            };
            const modules = [Autoplay, Navigation];
            return { 
                autoplayOptions,
                modules 
            };
        },
        data() {
            return {
                headerActive: '',
                depthNum: '',
                activeMenu: {},
                popupZone: false,
                topBanner: false,
                mainPage: '',
                drName: '',
                patName: '',
                patHpNo: '',
                title: '',
                hashTags: [],
                popupBannerList: [],
                lineBanner: [],
            }
        },
        methods: {
            getHashTags(){
                this.axios.get(this.api.search.hashKeywords).then(res => {
                    this.hashTags = res.data.result
                })
            },
            goDiseaseList(){
                if(!this.title){
                    alert('질환명을 입력해주세요.')
                    return
                }
                this.$router.push(`/healthinfo/diseaseList.do?title=${this.title}`)
            },
            searchDr(){
                if(!this.drName){
                    alert('의료진명을 입력해주세요.')
                    return
                }
                this.$router.push(`/hospital/department-doctor/doctor.do?currentNum=1&drName=${this.drName}`)
            },
            navClick() {
                document.querySelector('.site-map').classList.toggle('is-open');
                document.querySelector('body').classList.toggle('is-open');
            },
            navRemove() {
                document.querySelector('.site-map').classList.remove('is-open');
                document.querySelector('body').classList.remove('is-open');
            },
            menuEvent() {
                gsap.to('.header', { scrollTrigger: { trigger: '.header', start: '100px center', scrub: true, toggleClass: { targets: '.header', className: 'on' } } });
            },
            showContent(menu) {
                this.activeMenu = menu;
            },
            resetHeaderActive() {
                this.activeMenu = {};
                document.querySelector('body').classList.remove('is-open')
            },
            searchBtn() {
                // 현재 경로를 확인하여 메인 페이지인 경우
                if (this.$route.path === '/') {
                    // 메인 페이지에서 .sec-search로 스크롤 이동
                    const secSearchElement = document.querySelector('.sec-search');
                    if (secSearchElement) {
                        const secSearchPosition = secSearchElement.getBoundingClientRect().top + window.pageYOffset - 200;
                        window.scrollTo({ top: secSearchPosition, behavior: 'smooth' });
                    }
                } else {
                    // 서브 페이지인 경우
                    document.querySelector('body').classList.toggle('is-open2');
                    document.querySelector('.sub-search').classList.toggle('show');
                }
            },
            logout() {
                this.$store.commit('myInfoInit')
                this.axios
                    .get(this.api.member.logout)
                    .then((res) => {
                        // console.log(res)
                    })
                    .catch(err => {
                        console.log(err);
                    });
                this.$store.commit("setStatus", false);
                this.$store.commit('getShowMypageNameCheckModal', true);
                this.$store.commit('setMemCi', null);
                location.reload();
            },
            bannerFn() {
                this.topBanner = false
            },
            popupFn() {
                this.popupZone = false
            },
            search(searchType, keyword) {
                this.$router.push({ path: '/search.do', query: { searchKeyword: keyword } })
            },
            closePopup() {
                this.popupZone = false;
                const now = new Date().getTime()
                localStorage.setItem('popupZoneTime', now.toString())
            },
            getPopupBanner() {
                const param = {
                    instNo: 1,
                    bannerClsf: 'BA0000007'
                }
                this.axios.get(this.api.banner.list, {
                    params: param
                }).then(res => {
                    this.popupBannerList = res.data
                    if(res.data){
                        if(res.data.length > 0){
                            this.popupZone = true
                        }

                        const oneDay = 24 * 60 * 60 * 1000
                        const now = new Date().getTime()

                        const popupLastVisit = localStorage.getItem('popupZoneTime')
                        const popupLastVisitTime = popupLastVisit ? parseInt(popupLastVisit, 10) : null
                        const popupTimeSinceLastVisit = popupLastVisitTime ? now - popupLastVisitTime : null

                        if (popupLastVisitTime && popupTimeSinceLastVisit < oneDay) {
                            this.popupZone = false;
                        }
                    }else{
                        this.popupZone = false
                    }
                })
            },
            getLineBanner() {
                const param = {
                    instNo: 1,
                    bannerClsf: 'C'
                }
                this.axios.get(this.api.banner.list, {
                    params: param
                }).then(res => {
                    this.lineBanner = res.data
                    if(res.data){
                        if(res.data.length > 0){
                            this.topBanner = true
                        }
                    
                        const oneDay = 24 * 60 * 60 * 1000
                        const now = new Date().getTime()

                        const bannerLastVisit = localStorage.getItem('topBannerTime')
                        const bannerLastVisitTime = bannerLastVisit ? parseInt(bannerLastVisit, 10) : null
                        const bannerTimeSinceLastVisit = bannerLastVisitTime ? now - bannerLastVisitTime : null

                        if (bannerLastVisitTime && bannerTimeSinceLastVisit < oneDay) {
                            this.topBanner = false;
                        }
                    }else{
                        this.topBanner = false
                    }
                })
            },
            goBannerLink(item){
                if(!item.link) return
                if (item.target == '_blank') {
                    window.open(item.link);
                } else {
                    location.href = item.linkUrl;
                }
            },
            isExternal(url){
                return /^(http|https):/.test(url);
            },
            closeBanner() {
                this.topBanner = false;
                const now = new Date().getTime()
                localStorage.setItem('topBannerTime', now.toString())
            },
            goHome(){
                this.$store.commit('setCprsvInfo', '')
                this.$store.commit('setCprsv', '')
                this.$store.commit('init')
                this.$store.commit('setReserveInfo', '')
                this.$store.commit('setReserve', '')
                location.href = '/'
            }
        },
        mounted() {
            this.getLineBanner()
            this.getPopupBanner()
            // if (this.$route.path == '/') this.menuEvent();

            if (this.$route.path === '/') {
                this.menuEvent();
                this.mainPage = true
            } else {
                this.mainPage = false
            }

            this.getHashTags()
        },
        beforeUnmount() {
            let triggers = ScrollTrigger.getAll();
            triggers.forEach(trigger => {
                if (trigger.trigger == document.querySelector('.header')) trigger.kill();
            });
            document.querySelector('body').classList.remove('is-open2');
        }
    };
</script>