<template>
    <div class="wrap" :class="[{ 'health' : hpUrl == 'healthcenter'}, { 'enviro' : hpUrl == 'onecenter' }, { 'coop' : hpUrl == 'medicalcenter' }]">
        <div class="main-container">
            <div class="main-visual">
                <img :src="`/displayFile.do?name=${bannerList.pc[0].savedName}`" alt="이미지" class="main-visual__back" v-if="bannerList.pc.length > 0">
                <div class="main-visual__con">
                    <h3 class="main-visual__title">창원파티마병원 {{this.$parent.menuInfo.hpTitle}}</h3>
                    <p class="main-visual__text" v-if="hpUrl == 'healthcenter'">
                        개인별 맞춤형 건강검진을 통해 <br>
                        <strong> 질병의 조기발견과 치료를 돕고 있습니다.</strong>
                    </p>
                    <p class="main-visual__text" v-if="hpUrl == 'onecenter'">
                        지역민과 근로자의 건강한 미래를 위해 <br>
                        <strong>직업환경의학센터가 함께 합니다.</strong>
                    </p>
                    <p class="main-visual__text" v-if="hpUrl == 'medicalcenter'">
                        국내 유수의 의료기관과 협력하여 <br>
                        <strong>환자 중심의 원활한 진료 서비스를 받을 수 있도록 최선을 다하고 있습니다.</strong>
                    </p>
                </div>
            </div>
            <div class="quick-menu main-inner">
                <div class="quick-menu__text" v-if="hpUrl == 'healthcenter'">
                    <h3 class="quick-menu__text--title">문의 및 예약</h3>
                    <ul class="quick-menu__text--number">
                        <li><img src="@/assets/resource/icon/quick-menu__text--number01.png" alt="이미지"><a href="tel:0552701200" style="color:#fff;">055)270-1200~1</a></li>
                    </ul>
                    <p>
                        평일 09:00~16:40 · 토요일 09:00~12:00 
                    </p>
                </div>
                <div class="quick-menu__text" v-if="hpUrl == 'onecenter'">
                    <h3 class="quick-menu__text--title">문의 및 예약</h3>
                    <ul class="quick-menu__text--number">
                        <li><img src="@/assets/resource/icon/quick-menu__text--number01.png" alt="이미지"><a href="tel:0552701051" style="color:#fff;">055)270-1051~3</a></li>
                    </ul>
                    <p>
                        평일 09:00~17:00 <br> 토요일 09:00~12:00 
                    </p>
                </div>
                <div class="quick-menu__text" v-if="hpUrl == 'medicalcenter'">
                    <h3 class="quick-menu__text--title">문의 및 예약</h3>
                    <ul class="quick-menu__text--number">
                        <li><img src="@/assets/resource/icon/quick-menu__text--number01.png" alt="이미지"><a href="tel:0552701108" style="color:#fff;">055)270-1108</a></li>
                        <li><img src="@/assets/resource/icon/quick-menu__text--number02.png" alt="이미지">055)270-1029</li>
                    </ul>
                    <p>
                        평일 09:00~17:00 · 토요일 09:00~12:00
                    </p>
                    <p><b class="red__title">야간 및 응급</b>&nbsp;055)270-1119,1120</p>
                </div>
                <ul class="quick-menu__list">
                    <li class="quick-menu__item" v-for="quick in quickList.quick" :key="quick">
                        <a :href="quick.quickUrl" :target="quick.quickUrlMethod">
                            <img :src="`/displayFile.do?name=${quick.savedName}`" alt="이미지" v-if="quick.savedName != null">
                            <h4>{{quick.quickTitle}}</h4>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="form-download main-inner" v-if="hpUrl == 'medicalcenter'">
                <a href="/document-download-pdf.do?name=협력의료기관_신청서(진료협력센터).hwp" download="협력의료기관_신청서(진료협력센터).hwp">
                    <p>협력병원신청서 양식 다운로드</p>
                </a>
                <a href="/document-download-pdf.do?name=진료의뢰서(진료의뢰센터).hwp" download="진료의뢰서(진료의뢰센터).hwp">
                    <p>진료의뢰신청서 양식 다운로드</p>
                </a>
            </div>
            <template v-if="boardList.board.length > 0">
                <div class="canter-board" v-for="board in boardList.board" :key="board">
                    <div class="canter-board__title main-inner">
                        <h3>{{board.board.boardName}}</h3>
                        <a href="/news/list.do" target="_blank">
                            <p>더보기</p>
                        </a>
                    </div>
                    <div class="canter-board__list">
                        <div class="canter-board__header">
                            <ul class="canter-board__tr main-inner">
                                <li>NO.</li>
                                <li>제목</li>
                                <li>등록일</li>
                                <li></li>
                            </ul>
                        </div>
                        <ul class="canter-board__body">
                            <li class="canter-board__item" v-for="article in board.list" :key="article">
                                <ul class="canter-board__tr main-inner">
                                    <li>{{article.noticeYn == 'Y' ? '공지' : article.articleNo}}</li>
                                    <li><a :href="`/${board.board.boardId}/view.do?boardNo=${board.board.boardNo}&articleNo=${article.articleNo}`" target="_blank">{{article.title}}</a></li>
                                    <li>{{$commonUtils.dateToStr(new Date(article.createdDt), '-')}}</li>
                                    <li><a :href="`/${board.board.boardId}/view.do?boardNo=${board.board.boardNo}&articleNo=${article.articleNo}`" class="plus" target="_blank"></a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
            <ul class="photo-link main-inner" v-if="quickList.direct.length > 0">
                <li class="photo-link__item" v-for="direct in quickList.direct" :key="direct">
                    <img :src="`/displayFile.do?name=${direct.savedName}`" alt="이미지" v-if="direct.savedName != null" class="photo-link__item--back">
                    <a href="javascript:;" @click="goRes(direct)" v-if="direct.quickId == '346'">
                        <h3 class="photo-link__item--title">{{direct.quickTitle}}</h3>
                        <p class="photo-link__item--text">바로가기</p>
                    </a>
                    <a :href="direct.quickUrl" :target="direct.quickUrlMethod" v-else>
                        <h3 class="photo-link__item--title">{{direct.quickTitle}}</h3>
                        <p class="photo-link__item--text">바로가기</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    
    <!-- <div v-if="boardList.thumb.length > 0">
        <h3>thumb</h3>
        <div v-for="board in boardList.thumb" :key="board">
            <h4>{{ board.board.boardName }}</h4>
            <a :href="article.linkUrl" :target="article.linkMethod" v-for="article in board.list" :key="article">
                <div class="imgbox">
                    <img :src="`/displayFile.do?name=${article.attachBag.attach[0].savedName}`" alt="이미지" v-if="article.attachBag.attach">
                </div>
                <p>{{ article.title }}</p>
            </a>
        </div>
    </div> -->
</template>


<script>
export default {
    data() {
        return {
            deptCd: this.$route.params.deptCd,
            bannerList: {
                mo: [],
                pc: []
            },
            width: window.innerWidth,
            quickList: {
                quick: [],
                direct: []
            },
            boardList: {
                board: [],
                thumb: []
            },
            deptCd: this.$route.params.deptCd,
            hpUrl: this.$route.params.hpUrl,
        }
    },
    methods: {
        goRes(direct){
            alert('국가건강검진 결과조회를 위해 국민건강보험공단 사이트로 이동합니다.');
            window.open(direct.quickUrl)
        },
        getBanner() {
			this.axios.get(this.api.deptHp.banner, {
				params: {
					instNo: 1,
					deptCd: this.deptCd
				}
			})
				.then(res => {
                    this.bannerList.mo = res.data.mo
                    this.bannerList.pc = res.data.pc
                    console.log(this.bannerList)
				})
		},
        getQuick() {
			this.axios.get(this.api.deptHp.quick, {
				params: {
					instNo: 1,
					deptCd: this.deptCd
				}
			})
				.then(res => {
					for(let i of res.data){
						if(i.quickUseYn == 'Y'){
							this.quickList[i.quickType].push(i);
						}
					}
				})
		},
        getDeptHp() {
			this.axios.get(this.api.deptHp.hpSetting, {
				params: {
					instNo: 1,
					deptCd: this.deptCd,
				}
			}).then(res => {
                const list = res.data.bbsList
                if(list.length > 0){
                    for(let i of list){
                        const article = this.axios.get(this.api.article.list + i.boardNo, {
                            params: {
                                instNo: 1,
                                startIndex: 1,
                                pageRow: i.viewCnt,
                                boardNo: i.boardNo
                            }
                        }).then(article => {
                            i.areaType == 'A' ? this.boardList.board.push(article.data) : this.boardList.thumb.push(article.data)
                            console.log(this.boardList)
                        })
                    }
                }
            })
		},
    },
    mounted() {
        this.getBanner()
        this.getQuick()
        this.getDeptHp()
    },
};
</script>