<template>
    <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>
    <div class="mypage">
        <ul class="mypage__list">
            <li class="mypage__item item1">
                <p class="item1__tit"><b>{{$store.state.myInfoStore.memName}} </b>님 좋은 하루 보내세요!</p>
                <div class="mypage__text-box mt30" v-if="$commonUtils.isNotEmpty($store.state.myInfoStore.memPatId)" >
                    <p class="text">환자등록번호 : <b>{{$store.state.myInfoStore.memPatId}}</b></p>
                </div>
                <ul class="mypage__box-list">
                    <li class="mypage__box-item">
                        <router-link to="/mypage/member/updateMember.do" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/my-icon.png" alt="이미지"></div>
                            <div class="tit">회원정보수정</div>
                        </router-link>
                    </li>
                    <li class="mypage__box-item">
                        <router-link to="/mypage/member/changePassword.do" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/setting-icon.png" alt="이미지"></div>
                            <div class="tit">비밀번호변경</div>
                        </router-link>
                    </li>
                </ul>
            </li>

            <li class="mypage__item item2">
                <h3 class="item__tit">최근예약일정</h3>
                <div class="cont">
                    <!-- 일정이 없을 경우 노출 active : show -->
                    <p class="hiden-text show" v-if="reservationList.length === 0">최근2개월 내 예약된 진료일정이 없습니다.</p>

                    <template v-if="reservationList.length > 0">
                        <div class="img">
                            <img :src="`/displayFile.do?drNo=${reservationList[0].doctorInfo.drNo}`" alt="이미지">
                        </div>
                        <div class="text">
                            <div class="date">
                                {{ formatFullDate(reservationList[0].orddd) }}
                                {{ reservationList[0].ampmflagnm === '' ? formatTime(reservationList[0].ordtm) : reservationList[0].ampmflagnm }}
                            </div>
                            <div class="info">[외래] {{ reservationList[0].deptNm }} <b>{{ reservationList[0].hptlJobTitle ? reservationList[0].hptlJobTitle : reservationList[0].orddrnm }}</b></div>
                            <div class="btn">
                                <button type="button" @click="changeReservation">예약변경</button>
                                <button type="button" @click="reservationCancel">예약취소</button>
                            </div>
                        </div>
                    </template>
                </div>
            </li>

            <li class="mypage__item item3">
                <h3 class="item__tit">나의 작성글</h3>
                <div class="cont">
                    <ul class="item3__list">
                        <li class="item3__item">
                            <router-link to="/mypage/board/thanks.do">
                                <div class="tit">칭찬합니다.</div>
                                <div class="num">
                                    <div class="num__inner">
                                        <span>{{ thanksCnt }} </span> 건
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="item3__item">
                            <router-link to="/mypage/board/suggest.do">
                                <div class="tit">고객의 소리</div>
                                <div class="num">
                                    <div class="num__inner">
                                        <span>{{ suggestCnt }} </span> 건
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li class="item3__item">
                            <router-link to="/mypage/board/counsel.do">
                                <div class="tit">건강상담</div>
                                <div class="num">
                                    <div class="num__inner">
                                        <span>{{ counselCnt }} </span> 건
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="mypage__item item4">
                <h3 class="item__tit"><a href="javascript:;" @click="checkNameCert('/mypage/reservation/selectReservation.do')">예약 조회 <span class="arrow"></span></a></h3>
                <div class="line">
                    <div class="arrow arrow--bottom"></div>
                </div>
                <div class="cont">
                    <!-- 일정이 없을 경우 노출 active : show -->
                    <p class="hiden-text">예약된 진료일정이 없습니다.</p>
                    <div class="mypage__reservation-list mt-n" v-for="(item, index) in reservationList2">
                        <div class="mypage__text-box">
                            <div class="outpatient">외래</div>
                            <p class="text">{{ formatDate(item.orddd) }}</p>
                        </div>
                        <div class="mypage__time">
                            <span>{{ item.ampmflagnm === '' ? formatTime(item.ordtm) : item.ampmflagnm }} {{item.deptNm}}</span>
                            <span>{{item.doctorInfo.hptlJobTitle ? item.doctorInfo.hptlJobTitle : item.doctorInfo.drName}}</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="mypage__item item5">
                <h3 class="item__tit"><a href="#">결과조회</a></h3>
                <ul class="mypage__box-list">
                    <li class="mypage__box-item">
                        <a href="javascript:;" @click="checkNameCert('/mypage/result/out.do')" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon1.png" alt="이미지"></div>
                            <div class="tit">외래진료 내역</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" @click="checkNameCert('/mypage/result/drug.do')" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon2.png" alt="이미지"></div>
                            <div class="tit">약 처방 내역</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" @click="checkNameCert('/mypage/result/in.do')" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon3.png" alt="이미지"></div>
                            <div class="tit">입/퇴원 내역</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" @click="checkNameCert('/mypage/result/check.do')" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon4.png" alt="이미지"></div>
                            <div class="tit">검사결과</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="https://www.fatimahosp.co.kr/deptHp/1/healthcenter/checkUpResult.do" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon5.png" alt="이미지"></div>
                            <div class="tit">종합건강검진결과 <br>
                                (건강증진센터)</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link" @click="goRes">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon6.png" alt="이미지"></div>
                            <div class="tit">국가건강검진결과 <br>
                                (직업환경의학센터)</div>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="mypage__item item6">
                <h3 class="item__tit"><router-link to="/mypage/health/healthCare.do">나의 건강관리 <span class="arrow"></span></router-link></h3>
                <div class="cont">
                    <ul class="health-list">
                        <li class="health-item item1">
                            <router-link to="/mypage/health/bloodPressure.do">
                                <div class="tit">
                                    <h4>혈압</h4>
                                    <div class="img">
                                        <img src="@/assets/resource/icon/item6-icon1.png" alt="이미지">
                                    </div>
                                </div>
                                <div class="desc">
                                    <template v-if="healthRecord.BP">
                                        <div class="num"><b>{{healthRecord.BP.record2}}~{{healthRecord.BP.record1}}</b> mmHg</div>
                                        <div class="state">{{ calcBP(healthRecord.BP) }}</div>
                                    </template>

                                    <div class="no-data" v-if="healthRecord.BP === null">
                                        <p>등록된 데이터가 없습니다.</p>
                                        <button type="button"><span class="plus"></span>혈압 등록</button>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="health-item item2">
                            <router-link to="/mypage/health/bloodSugar.do">
                                <div class="tit">
                                    <h4>혈당</h4>
                                    <div class="img">
                                        <img src="@/assets/resource/icon/item6-icon2.png" alt="이미지">
                                    </div>
                                </div>
                                <div class="desc">
                                    <template v-if="healthRecord.BS">
                                        <div class="num"><b>{{ healthRecord.BS.record2 }}</b> mg/dL</div>
                                        <div class="state">{{ calcBS(healthRecord.BS) }}입니다.</div>
                                    </template>

                                    <div class="no-data" v-if="healthRecord.BS === null">
                                        <p>등록된 데이터가 없습니다.</p>
                                        <button type="button"><span class="plus"></span>혈당 등록</button>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="health-item item3">
                            <router-link to="/mypage/health/bodyMass.do">
                                <div class="tit">
                                    <h4>체질량</h4>
                                    <div class="img">
                                        <img src="@/assets/resource/icon/item6-icon3.png" alt="이미지">
                                    </div>
                                </div>
                                <div class="desc">
                                    <template v-if="healthRecord.BM">
                                        <div class="num"><b>{{ healthRecord.BM.record1 }}</b>cm/<b>{{ healthRecord.BM.record2 }}</b> KG</div>
                                        <div class="state">{{ calcBMI(healthRecord.BM) }}입니다.</div>
                                    </template>

                                    <div class="no-data" v-if="healthRecord.BM === null">
                                        <p>등록된 데이터가 없습니다.</p>
                                        <button type="button"><span class="plus"></span>정보 등록</button>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="mypage__item item8">
                <h3 class="item__tit"><a href="#">관심 정보</a></h3>
                <div class="cont">
                    <router-link to="/mypage/favo/favoDoctors.do" class="mypage__link">관심 의료진</router-link>
                    <router-link to="/mypage/favo/favoContents.do" class="mypage__link">관심 콘텐츠</router-link>
                </div>
            </li>

            <li class="mypage__item item7">
                <h3 class="item__tit"><a href="#">진료순번조회</a></h3>
                <div class="cont">
                    <router-link to="/mypage/queue/medicalQueue.do" class="mypage__link">순번조회</router-link>
                </div>
            </li>

            <li class="mypage__item item9">
                <h3 class="item__tit"><router-link to="/mypage/member/outMember.do">회원탈퇴 <span class="arrow"></span></router-link></h3>
            </li>
        </ul>
    </div>
</template>
<script>
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'

export default {
    name: "mypage",
    components: {
        NameCertifyPopUp
    },
    data() {
        return {
            today: '',
            nextYear: '',
            dataList: [],
            reservationList: [],
            reservationList2: [],
            suggestCnt: 0,
            thanksCnt: 0,
            counselCnt: 0,
            healthRecord: {BP:{},BS:{},BM:{}},
            popupVisible: false,
        };
    },
    methods: {
        goRes(){
            alert('국가건강검진 결과조회를 위해 국민건강보험공단 사이트로 이동합니다.');
            window.open('https://www.nhis.or.kr/nhis/etc/personalLoginPage.do')
        },
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        async getReservationList() {
            //YYYYMMDD 형식으로 현재일, 2달뒤 날짜를 구해서 API 호출
            let today = new Date();
            const fromdd = today.toISOString().slice(0, 10).replace(/-/g, '');

            let twoMonthLater = new Date(today.setMonth(today.getMonth() + 2));
            const todd = twoMonthLater.toISOString().slice(0, 10).replace(/-/g, '');

            try {
                this.reservationList = []
                const response = await this.axios.get(this.api.mypage.reservationList, {
                    params: {
                        fromdd: fromdd,
                        todd: todd
                    }
                });
                for (let i of response.data.list) {
                    let doc = response.data.doclist.find((val) => i.orddrid == val.empId)
                    i.doctorInfo = doc;
                    this.reservationList.push(i);
                }
                //this.dataList = response.data
            } catch (err) {
                console.error(err);
            }
            //========= 10년이내 데이터
            let today2 = new Date();
            const fromdd2 = today2.toISOString().slice(0, 10).replace(/-/g, '');

            let tenYearLater = new Date(today.setFullYear(today2.getFullYear() + 10));
            const todd2 = tenYearLater.toISOString().slice(0, 10).replace(/-/g, '');

            try {
                this.reservationList2 = []
                const response = await this.axios.get(this.api.mypage.reservationList, {
                    params: {
                        fromdd: fromdd2,
                        todd: todd2
                    }
                });
                for (let i of response.data.list) {
                    let doc = response.data.doclist.find((val) => i.orddrid == val.empId)
                    i.doctorInfo = doc;
                    this.reservationList2.push(i);
                }
                // console.log("data: "+ JSON.stringify(this.reservationList2));
                //this.dataList = response.data
            } catch (err) {
                console.error(err);
            }
        },
        formatFullDate(dateString) {
            const date = new Date(dateString.slice(0, 4), dateString.slice(4, 6) - 1, dateString.slice(6, 8));

            const weekDays = ['일', '월', '화', '수', '목', '금', '토'];
            const weekDay = weekDays[date.getDay()];

            const dateResult = this.$commonUtils.dateToStr(date, '-')

            return `${dateResult}(${weekDay})`;
        },
        formatDate(dateString) {
            const date = new Date(dateString.slice(0, 4), dateString.slice(4, 6) - 1, dateString.slice(6, 8));

            const month = date.getMonth() + 1;
            const day = date.getDate();
            const weekDays = ['일', '월', '화', '수', '목', '금', '토'];
            const weekDay = weekDays[date.getDay()];

            return `${month}월 ${day}일 (${weekDay})`;
        },
        formatTime(timeString) {
            const hours = timeString.slice(0, 2);
            const minutes = timeString.slice(2, 4);

            return `${hours}:${minutes}`;
        },
        getMainInfo(){
            this.axios({
                method:"get",
                url: this.api.mypage.mainInfo,
                params: {
                    'suggestNo' : 12,
                    'thanksNo' : 5
                }
            }).then(response => {
                this.suggestCnt = response.data.suggestCnt;
                this.thanksCnt = response.data.thanksCnt;
                this.counselCnt = response.data.counselCnt;
                this.healthRecord.BP = response.data.healthRecordBP;
                this.healthRecord.BS = response.data.healthRecordBS;
                this.healthRecord.BM = response.data.healthRecordBM;
            });
        },
        calcBMI(bm){
            const bmi = (bm.record2 / ((bm.record1 / 100) * (bm.record1 / 100))).toFixed(2);
            let result = '';
            if(bmi < 18.5){
                result = '저체중';
            }else if(bmi >= 18.5 && bmi < 23){
                result = '정상';
            }else if(bmi >= 23 && bmi < 25){
                result = '과체중';
            }else if(bmi >= 25 && bmi < 30){
                result = '비만';
            }else{
                result = '고도비만';
            }

            return result;

        },
        calcBS(bs){
            let result = '';
            if(bs.record2 < 70){
                result = '저혈당';
            }else if(bs.record2 >= 70 && bs.record2 < 100){
                result = '정상';
            }else if(bs.record2 >= 100 && bs.record2 < 126){
                result = '당뇨 전단계';
            }else{
                result = '당뇨병';
            }

            return result;
        },
        calcBP(bp){
            let result = '';
            if(bp.record1 < 120 && bp.record2 < 80){
                result = '정상';
            }else if(bp.record1 >= 120 && bp.record1 < 130 && bp.record2 < 80){
                result = '주의';
            }else if(bp.record1 >= 130 && bp.record1 < 140 || bp.record2 >= 80 && bp.record2 < 90){
                result = '고혈압 전단계';
            }else if(bp.record1 >= 140 && bp.record1 < 160 || bp.record2 >= 90 && bp.record2 < 100){
                result = '고혈압 1기';
            }else if(bp.record1 >= 160 && bp.record1 < 180 || bp.record2 >= 100 && bp.record2 < 110){
                result = '고혈압 2기';
            }else{
                result = '고혈압 3기';
            }

            return result;
        },
        checkNameCert(url){
            if(!this.$store.state.myInfoStore) {
                alert('실명인증을 해주시길 바랍니다.')
                return
            }
            this.$router.push(url)
        },
        changeReservation() {
            if (confirm('예약 변경 시 기존 예약은 취소됩니다. 진행하시겠습니까?')) {
                this.$store.commit('setChange', this.reservationList[0])
                this.$router.push({ path: '/reserve/reservation.do'});
            }
        },
        reservationCancel(){
            if (confirm('예약을 취소하시겠습니까?')) {
                const item = this.reservationList[0]
                const param = {
                    sessuserid : '90000300',
                    flag : 'D', // 접수: I, 취소: D
                    // pid : this.myInfo.memPatId,
                    orddd : item.orddd,
                    ordtm : item.ordtm,
                    orddeptcd : item.orddeptcd,
                    orddrid : item.orddrid,
                    cretno : item.cretno, // 일련번호
                    // insukind : '',
                    // suppkind : '',
                    // rsrvflag : '6',
                    // etcordflag : 'R6'
                }

                this.axios({
                    method: 'post',
                    url: this.api.appointment.reservation,
                    params: param
                }).then(res => {
                    if(res.data.errormsg){
                        alert(res.data.errormsg)
                        return
                    }
                    if(res.data.code){
                        alert(res.data.code)
                        return
                    }
                    alert('예약이 취소되었습니다.')
                    this.getReservationList()
                }).catch(err => {
                    alert('오류가 발생했습니다.')
                    location.reload(true);
                })
            }
        },
    },
    async mounted() {
        const myInfo = this.$store.state.myInfoStore
        if((myInfo.memPatId == '' || myInfo.memPatId == null) && this.$store.getters.getMypageModal){
            this.popupVisible = true
        }else{
            this.popupVisible = false
        }
        this.getMainInfo();
        this.getReservationList();
    },
    beforeRouteUpdate (to, from, next) {
        console.log(to, from)
    }
};
</script>