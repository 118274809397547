//disabled
export const disabled = {
    "M":{
        "course":['여성정밀 A코스','여성정밀 B코스(대장내시경 제외)'],
        "option":['유방초음파','여성호르몬검사','HPV DNA']
    },
    "F":{
        "course":['남성정밀코스'],
        "option":['전립선초음파','남성호르몬검사']
    }
}
//course
export const course = {
  "그린코스": {
      "M": {
          "CODE": "HA0001",
          "PRICE": 480000,
          "disabledOptionList":['뇌심혈관계발병위험도']
      },
      "F": {
          "CODE": "HA0002",
          "PRICE": 530000,
          "optionList":['HPV DNA'],
          "disabledOptionList":['뇌심혈관계발병위험도']
      }
  },
  "블루코스": {
      "M": {
          "CODE": "HA0003",
          "PRICE": 350000,
          "disabledOptionList":['뇌심혈관계발병위험도']
      },
      "F": {
          "CODE": "HA0004",
          "PRICE": 380000,
          "disabledOptionList":['뇌심혈관계발병위험도']
      }
  },
  "골드코스": {
      "M": {
          "CODE": "HA0005",
          "PRICE": 1100000,
          "optionList":['MD-CT(흉부)','전립선초음파','골밀도검사','생체나이검사','대장내시경','남성호르몬검사','동맥경화도검사'],
          "disabledOptionList":['수면내시경', '뇌심혈관계발병위험도']
      },
      "F": {
          "CODE": "HA0006",
          "PRICE": 1150000,
          "optionList":['MD-CT(흉부)','유방초음파','골밀도검사','생체나이검사','대장내시경','여성호르몬검사','동맥경화도검사','HPV DNA'],
          "disabledOptionList":['수면내시경', '뇌심혈관계발병위험도']
      }
  },
  "실버코스": {
      "M": {
          "CODE": "HA0007",
          "PRICE": 1450000,
          "optionList":['뇌MRI+MRA','MD-CT(흉부)','전립선초음파','골밀도검사','생체나이검사','동맥경화도검사'],
          "disabledOptionList":['갑상선초음파']
      },
      "F": {
          "CODE": "HA0008",
          "PRICE": 1500000,
          "optionList":['뇌MRI+MRA','MD-CT(흉부)','갑상선초음파','골밀도검사','생체나이검사','동맥경화도검사'],
          "disabledOptionList":['갑상선초음파']
      }
  },
  "남성정밀코스": {
      "M": {
          "CODE": "HA0009",
          "PRICE": 930000,
          "selectOption":['MD-CT(흉부)','대장내시경'],
          "optionList":['전립선초음파','생체나이검사','남성호르몬검사','뇌심혈관계발병위험도']
      }
  },
  "여성정밀 A코스": {
      "F": {
          "CODE": "HA0010",
          "PRICE": 1100000,
          "optionList":['유방초음파','갑상선초음파','골밀도검사','생체나이검사','대장내시경','여성호르몬검사','HPV DNA'],
          "disabledOptionList":['수면내시경']
      }
  },
  "여성정밀 B코스(대장내시경 제외)": {
      "F": {
          "CODE": "HA0011",
          "PRICE": 950000,
          "optionList":['유방초음파','갑상선초음파','골밀도검사','생체나이검사','여성호르몬검사','HPV DNA']
      }
  },
  "소화기정밀코스": {
      "M": {
          "CODE": "HA0012",
          "PRICE": 900000,
          "optionList":['MD-CT(복부/골반)','골밀도검사','생체나이검사','대장내시경'],
          "disabledOptionList":['수면내시경','뇌심혈관계발병위험도']
      },
      "F": {
          "CODE": "HA0013",
          "PRICE": 950000,
          "optionList":['MD-CT(복부/골반)','골밀도검사','생체나이검사','대장내시경','HPV DNA'],
          "disabledOptionList":['수면내시경','뇌심혈관계발병위험도']
      }
  },
  "호흡기정밀코스": {
      "M": {
          "CODE": "HA0016",
          "PRICE": 700000,
          "optionList":['MD-CT(흉부)','생체나이검사'],
          "disabledOptionList":['뇌심혈관계발병위험도']
      },
      "F": {
          "CODE": "HA0017",
          "PRICE": 750000,
          "optionList":['MD-CT(흉부)','생체나이검사','HPV DNA'],
          "disabledOptionList":['뇌심혈관계발병위험도']
      }
  },
  "순환기정밀코스": {
      "M": {
          "CODE": "HA0014",
          "PRICE": 950000,
          "optionList":['심장CT (Ca)','경동맥초음파','심장초음파','생체나이검사','동맥경화도검사']
      },
      "F": {
          "CODE": "HA0015",
          "PRICE": 1000000,
          "optionList":['심장CT (Ca)','경동맥초음파','심장초음파','생체나이검사','동맥경화도검사','HPV DNA']
      }
  },
  "뇌정밀코스": {
      "M": {
          "CODE": "HA0018",
          "PRICE": 1400000,
          "optionList":['뇌MRI+MRA','경동맥초음파','뇌혈류초음파','생체나이검사','스트레스검사','동맥경화도검사']
      },
      "F": {
          "CODE": "HA0019",
          "PRICE": 1450000,
          "optionList":['뇌MRI+MRA','경동맥초음파','뇌혈류초음파','생체나이검사','스트레스검사','동맥경화도검사','HPV DNA']
      }
  },
  "조기암(PET-CT)검진": {
      "M": {
          "CODE": "HA0024",
          "PRICE": 1400000
      },
      "F": {
          "CODE": "HA0025",
          "PRICE": 1450000,
          "optionList":['HPV DNA']
      }
  },
  "종합정밀 I(당일)": {
      "M": {
          "CODE": "HA0020",
          "PRICE": 2150000,
          "optionList":['뇌MRI+MRA','MD-CT(흉부)','MD-CT(복부/골반)','심장초음파','전립선초음파','골밀도검사','생체나이검사','대장내시경','남성호르몬검사','동맥경화도검사'],
          "disabledOptionList":['수면내시경']
      },
      "F": {
          "CODE": "HA0021",
          "PRICE": 2200000,
          "optionList":['뇌MRI+MRA','MD-CT(흉부)','MD-CT(복부/골반)','유방초음파','심장초음파','골밀도검사','생체나이검사','대장내시경','여성호르몬검사','동맥경화도검사','HPV DNA'],
          "disabledOptionList":['수면내시경']
      }
  },
  "종합정밀 II(숙박)": {
      "M": {
          "CODE": "HA0022",
          "PRICE": 3000000,
          "optionList":['뇌MRI+MRA','MD-CT(흉부)','MD-CT(복부/골반)','심장CT (Ca)','경동맥초음파','뇌혈류초음파','갑상선초음파','심장초음파','전립선초음파','골밀도검사','운동부하검사','생체나이검사','대장내시경','남성호르몬검사','스트레스검사','동맥경화도검사'],
          "disabledOptionList":['수면내시경']
      },
      "F": {
          "CODE": "HA0023",
          "PRICE": 3000000,
          "optionList":['뇌MRI+MRA','MD-CT(흉부)','MD-CT(복부/골반)','심장CT (Ca)','경동맥초음파','뇌혈류초음파','갑상선초음파','심장초음파','골밀도검사','운동부하검사','생체나이검사','대장내시경','여성호르몬검사','동맥경화도검사','HPV DNA','유방초음파','스트레스검사'],
          "disabledOptionList":['수면내시경']
      }
  }
}
//option
export const optionList = {
  "뇌MRI+MRA": {
      "CODE": "HPC90051",
      "PRICE": 800000
  },
  "MD-CT(흉부)": {
      "CODE": "HPC674",
      "PRICE": 200000
  },
  "MD-CT(뇌)": {
      "CODE": "HPC677",
      "PRICE": 200000
  },
  "MD-CT(복부/골반)": {
      "CODE": "HPC676",
      "PRICE": 200000
  },
  "MD-CT(뇌혈관)": {
      "CODE": "XO071",
      "PRICE": 200000
  },
  "MD-CT(경동맥)": {
      "CODE": "XC044",
      "PRICE": 200000
  },
  "심장CT (Ca)": {
      "CODE": "HPC187",
      "PRICE": 150000
  },
  "유방초음파": {
      "CODE": "HPC90068",
      "PRICE": 160000
  },
  "경동맥초음파": {
      "CODE": "HPC90049",
      "PRICE": 130000
  },
  "뇌혈류초음파": {
      "CODE": "HPC90071",
      "PRICE": 200000
  },
  "갑상선초음파": {
      "CODE": "HPC90048",
      "PRICE": 130000
  },
  "심장초음파": {
      "CODE": "HPC90050",
      "PRICE": 180000
  },
  "전립선초음파": {
      "CODE": "HPC90047",
      "PRICE": 130000
  },
  "골밀도검사": {
      "CODE": "HPC103",
      "PRICE": 70000
  },
  "운동부하검사": {
      "CODE": "HPC106",
      "PRICE": 50000
  },
  "생체나이검사": {
      "CODE": "HPC90002",
      "PRICE": 40000
  },
  "수면내시경": {
      "CODE": "HPC90053",
      "PRICE": 80000
  },
  "대장내시경": {
      "CODE": "HPC88",
      "PRICE": 200000
  },
  "남성호르몬검사": {
      "CODE": "HPC96",
      "PRICE": 30000
  },
  "여성호르몬검사": {
      "CODE": "HPC120",
      "PRICE": 50000
  },
  "뇌심혈관계발병위험도": {
      "CODE": "HPC90074",
      "PRICE": 30000
  },
  "스트레스검사": {
      "CODE": "HPC153",
      "PRICE": 30000
  },
  "동맥경화도검사": {
      "CODE": "HPC154",
      "PRICE": 50000
  },
  "PET-CT(전신)": {
      "CODE": "PET_CT01",
      "PRICE": 1100000
  },
  "PET-CT(상반신)": {
      "CODE": "PET_CT02",
      "PRICE": 750000
  },
  "HPV DNA": {
      "CODE": "HPC131",
      "PRICE": 70000
  }
}