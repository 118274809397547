<template>
    <div class="programInfo">
        <div class="tab-menu__wrap">
            <div class="tab-menu type02">
                <ul class="tab-menu__list">
                    <li class="tab-menu__item" :class="{active : course === 'green' || course === '' }">
                        <button type="button" class="tab-menu__btn" @click="course = 'green'">그린 코스</button>
                    </li>
                    <li class="tab-menu__item" :class="{active : course === 'blue' }">
                        <button type="button" class="tab-menu__btn" @click="course = 'blue'">블루 코스</button>
                    </li>
                    <li class="tab-menu__item" :class="{active : course === 'gold' }">
                        <button type="button" class="tab-menu__btn" @click="course = 'gold'">골드 코스</button>
                    </li>
                    <li class="tab-menu__item" :class="{active : course === 'silver' }">
                        <button type="button" class="tab-menu__btn" @click="course = 'silver'">실버 코스</button>
                    </li>
                </ul>
            </div>
        </div>
        

        <div class="contents" :class="{active : course === 'green' || course === ''}">

            <div ref="programInfo" class="program_add_wrap">

                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">그린 코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            우리나라에서 유병률 및 발생률이 높은 생활습관병 및 암을 조기진단하기 위한 가장 기본적인 건강검진 프로그램 입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 2시간 / 월요일~토요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 남 480,000원 / 여 530,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive }" @click="addClass">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="11" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀))
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                             <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사</td>
                            </tr>
                            <tr>
                                <td rowspan="2" style="text-align: center;">여성</td>
                                <td>유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" :class="{active : course === 'blue'}">

            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">블루 코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            미래세대(수험생 또는 20대초반)의 신체적 특성을 고려한 전문건강검진 프로그램 입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 2시간 / 월요일~토요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 남 350,000원 / 여 380,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive2 }" @click="addClass2">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="11" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(A, B, C형간염), 매독,
                                    AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">척추측만검사</td>
                                <td>요추 X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 스트레스 측정검사</td>
                            </tr>
                            <tr>
                                <td style="text-align: center;">여성</td>
                                <td>풍진검사</td>
                                <td>풍진 항체검사</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" :class="{active : course === 'gold' }">

            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">골드 코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            중년이후의 남녀를 대상으로 갱년기에 흔히 발생할 수 있는 질환의 가능성을 확인하고 건강한 노후를 대비하고자 하시는 분을 위한 건강검진
                                            프로그램 입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~금요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 남 1,100,000원 / 여 1,150,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive3 }" @click="addClass3">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="15" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암, 전립선암(♂), 난소암(♀))
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 저선량 흉부CT</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                                <td>대장내시경</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                                <td>전립선초음파(♂), 유방초음파(♀)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                                <td>골밀도검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                                <td>남성호르몬(♂), 여성호르몬(♀)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사, 동맥경화도검사</td>
                            </tr>
                            <tr>
                                <td rowspan="2" style="text-align: center;">여성</td>
                                <td>유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 액상자궁경부암 검사, 인유두종바이러스(HPV-DNA)</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" :class="{active : course === 'silver' }">

            <div ref="programInfo" class="program_add_wrap">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">실버 코스</h4>
                        <!-- depth02 -->
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <!-- depth03 -->
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            건강한 노후를 보내기 위해 협심증, 뇌경색, 암 등 조기검사가 필요한 항목들로 이루어진 포괄적인 건강검진프로그램 입니다.
                                        </p>
                                        <div class="depth__wrap mt20">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사 소요시간 : 약 3시간이상 / 월요일~금요일
                                                </p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>
                                                    검사비용 : 남 1,450,000원 / 여 1,500,000원
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive4 }" @click="addClass4">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width: 13%">
                                <col style="width: 17%">
                                <col style="width: 70%">
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowspan="14" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">문진</td>
                                <td>건강상담</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                                <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    청력, 안과, 치과
                                </td>
                                <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                                <td>
                                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사 간염검사(B, C형간염), 매독, AIDS,
                                    류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암, 전립선암(♂), 난소암(♀)),
                                    심혈관정밀3종(호모시스테인, CPK, CRF)
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">
                                    대, 소변 검사
                                </td>
                                <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 저선량 흉부CT</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                                <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                                <td>복부 초음파</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                                <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">뇌검사</td>
                                <td>뇌 MRI &amp; MRA</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                                <td>전립선초음파(♂), 갑상선초음파(♀)</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                                <td>골밀도검사</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                                <td>뇌심혈관계발병위험도평가, 대사증후군검사, 생체나이측정검사, 동맥경화도검사</td>
                            </tr>
                            <tr>
                                <td rowspan="2" style="text-align: center;">여성</td>
                                <td>유방검사</td>
                                <td>유방X-ray</td>
                            </tr>
                            <tr>
                                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                                <td>자궁초음파, 액상자궁경부암 검사</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="btn-wrap center contents"><a href="javascript:;" class="btn" @click="getCheckup">예약하기</a></div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            course: this.$route.query.course ? this.$route.query.course : '',
            isActive: false,
            isActive2: false,
            isActive3: false,
            isActive4: false,
        }
    },
    methods: {
        getCheckup() {
            let program = ''
            if(this.course === 'green' || this.course === ''){
                program = '그린코스'
            } else if(this.course === 'blue'){
                program = '블루코스'
            } else if(this.course === 'gold'){
                program = '골드코스'
            } else if(this.course === 'silver'){
                program = '실버코스'
            }

            if (this.$store.state.memberStore.status && this.$store.state.myInfoStore.memGender) {
                if (program == '여성정밀 A코스' && this.$store.state.myInfoStore.memGender == 'M') {
                    alert('해당 코스는 여성전용 건강검진 코스입니다.')
                    return
                }
            }
            const data = {
                program: program,
                option: []
            }
            this.$store.commit('setCprsvInfo', data)
            const url = `/deptHp/${this.$route.params.deptCd}/${this.$route.params.hpUrl}/PersonalReservation.do`
            window.open(url)
            // this.$router.push(url)
        },
        addClass() {
            if (!this.isActive) {
                this.isActive = true;
            }
        },
        addClass2() {
            if (!this.isActive2) {
                this.isActive2 = true;
            }
        },
        addClass3() {
            if (!this.isActive3) {
                this.isActive3 = true;
            }
        },
        addClass4() {
            if (!this.isActive4) {
                this.isActive4 = true;
            }
        },
    },
    mounted() {
         const buttons = document.querySelectorAll('.tab-menu__item');
            buttons.forEach(button => {
            button.addEventListener('click', (event) => {
                buttons.forEach(btn => btn.classList.remove('on'));
                event.currentTarget.classList.add('on');
            });
        });
        
    },
}
</script>